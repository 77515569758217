import React from "react";
import { View, StyleSheet, Button, Platform } from "react-native";
import { Colors } from "../../config";
import StarCatcher from "../../Games/starcatcher";

export const GamesScreen = () => {

  const gameinfos = {
    id: 1,
    name: 'StarCatcher',
    maxTime: 60000,
    isTimerWithMaxTime: true,
    starCount: 14,
    description: 'Sammel die Sterne so schnell wie möglich'
  };


  return (
    <View style={styles.container}>

    </View>
  );
};

const styles = StyleSheet.create({
  container: {

    backgroundColor: Colors.white,
    paddingHorizontal: 12,

    flex: 1,
    backgroundColor: "#000000",
    //alignItems: "center",
    //justifyContent: "center",
  },
});

