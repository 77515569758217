import { StyleSheet, Text, View, TouchableOpacity, Platform, StatusBar, Easing, Animated } from 'react-native'
import React, { useRef, useState } from 'react'
import { Colors } from '../../config';

export const AboutUsScreen = () => {
  return (
    <View style={styles.container}>

    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.transparent,
    paddingHorizontal: 12,
    ...Platform.select({
      ios: {
        
      },
      android: {
        
      },
      default: {
        // other platforms, web for example
       
        justifyContent: 'center',
    alignItems: 'center'
      }
    })
  }
});
