import React from 'react';
import {StyleSheet,Platform,StatusBar } from 'react-native';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { View } from 'react-native';
import { Colors } from './config';
import { RootNavigator } from './navigation/RootNavigator';
import { AppStateIndicatorProvider, AuthenticatedUserProvider } from './providers';
import 'react-native-gesture-handler';
import Constants from './Constants';

const App = () => {
  return (
    <View style={styles.container}>
    <AuthenticatedUserProvider >
      <SafeAreaProvider>
        <StatusBar/>
        <RootNavigator />
      </SafeAreaProvider>
    </AuthenticatedUserProvider>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.backgroundColor,
    justifyContent: 'center',
   
  }
});

export default App;
