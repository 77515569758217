import { createStackNavigator, CardStyleInterpolators } from '@react-navigation/stack';
import React from 'react';
import { View, StyleSheet, Button, Platform } from 'react-native';
import { Colors, Images } from '../../config';
import Constants from '../../Constants';
import { MainAcivityNavigation } from '../../navigation';
import { BestPlayerContent, History, InformationCenter, PlayerTimes } from '../feutures/screens';



export const HomeScreen = ({navigation}) => {

  const Stack = createStackNavigator();

  return (
    <View style={styles.container}>

      {
        // beginn TopArea
      }

      <View style={styles.topArea} >

        <MainAcivityNavigation navigation={navigation} />


      </View>

      {
        // ende TopArea
        // beginn mainArea
      }
        <View style={styles.centerArea} >
          <Stack.Navigator
            screenOptions={{
              headerShown: false,
              cardStyle: {
                backgroundColor: Colors.transparent,
                cardStyleInterpolator: CardStyleInterpolators.forFadeFromCenter
              }
            }}
          >
            <Stack.Screen name='BestPlayer' component={BestPlayerContent} />
            <Stack.Screen name='PlayerTimes' component={PlayerTimes} />
            <Stack.Screen name='InfoCenter' component={InformationCenter} />
            <Stack.Screen name='History' component={History} />

          </Stack.Navigator>

        </View>
     
      {
        // ende MainArea
        // beginn ButtomArea
      }

      <View style={styles.buttomArea} >

      </View>

    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.transparent,
    paddingHorizontal: 12,

  },

  topArea: {
    flex: 1,


  },
  centerArea: {
    flex: 10,
  },
  buttomArea: {
    flex: 1,

  }

});
