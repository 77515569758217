import { StyleSheet, Text, View } from 'react-native'
import React from 'react'

export const History = () => {
  return (
    <View>
      <Text>History</Text>
    </View>
  )
}


const styles = StyleSheet.create({})