import { NavigationContainer, useNavigationContainerRef } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import { View, StyleSheet, Button, Platform, Text } from 'react-native';
import { Colors } from '../../config';
import { auth } from '../../config';
import { PayPal, ProfileBanner, ProfileStateInfos } from '../feutures/screens';


export const ProfileScreen = ({navigation}) => {

  const Stack = createStackNavigator();
  const config = {
    animation: 'spring',
    config: {
      stiffness: 1000,
      damping: 500,
      mass: 3,
      overshootClamping: true,
      restDisplacementThreshold: 0.01,
      restSpeedThreshold: 0.01,
    },
  };
  return (
    <View style={styles.container}>

      <View style={styles.toparea} >

        <ProfileBanner navigation={navigation} />

      </View>

      <View style={styles.mainarea} >
      
          <Stack.Navigator
            
            screenOptions={{
              headerShown: false,
              cardStyle: {
                backgroundColor: Colors.transparent,
              }
            }
            }>
            
            <Stack.Screen name='profileinfos' component={ProfileStateInfos} 
            options={{
              transitionSpec: {
                open: config,
                close: config,
              },
            }}/>
            <Stack.Screen name='paypal' component={PayPal} 
            options={{
              transitionSpec: {
                open: config,
                close: config,
              },
            }}/>
          </Stack.Navigator>
      </View>


    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.transparent,

  },

  toparea: {

    flex: 1,
    marginVertical: 25

  },
  mainarea: {
    flex: 5,

  }

});
