import { auth, storage } from "../config";
import { useState, useEffect } from "react";
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";
import { Platform } from "react-native";



export const firestorage = () => {

    let imagesRef = ref(storage, 'images/' + auth.currentUser.uid + '/profile/profile.jpg');

    const [imgURL, setImageURL] = useState(null);

    useEffect(() => {
        downloadProfilePic();
    }, [])



    const downloadProfilePic = () => {

        getDownloadURL(imagesRef)
            .then((url) => {
                setImageURL(url);
            })
            .catch((error) => {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                    case 'storage/object-not-found':

                        console.log('error');
                        const urls = 'https://media.istockphoto.com/id/825383494/photo/business-man-pushing-large-stone-up-to-hill-business-heavy-tasks-and-problems-concept.jpg?s=612x612&w=0&k=20&c=wtqvbQ6OIHitRVDPTtoT_1HKUAOgyqa7YzzTMXqGRaQ=';
                        setImageURL(urls);
                        break;
                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        console.log('error');
                        break;
                    case 'storage/canceled':
                        // User canceled the upload
                        console.log('error');
                        break;

                    case 'storage/unknown':
                        // Unknown error occurred, inspect the server response
                        console.log('error');
                        break;
                }
            });
    }


    const setProfileImage = async (file) => {


        switch (Platform.OS) {

            case 'android':



                const metatadata = {
                    type: file.mimeType,
                    size: file.size,
                }

                const r = await fetch(file.uri);
                const b = await r.blob();
                
                console.log(file);
                if (file.type !== 'cancel') {
                    imagesRef = ref(storage, 'images/' + auth.currentUser.uid + '/profile/profile.jpg');
                    uploadBytes(imagesRef, b, metatadata).then((snapshot) => {
                        downloadProfilePic();
                    });
                }

                break;


            case 'web':

                console.log(file);
                imagesRef = ref(storage, 'images/' + auth.currentUser.uid + '/profile/profile.jpg');
                uploadBytes(imagesRef, file.file).then((snapshot) => {
                    downloadProfilePic();
                });

                break;


        }






    }

    return {
        imgURL, setProfileImage
    }
}