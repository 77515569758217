import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { Colors } from '../../../../../config'
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import { firelistener, firestorage } from '../../../../../hooks'

export const ProfileStateInfos = () => {

    const { wallet, nickname } = firelistener();

    const { imgURL, setProfileImage } = firestorage();

    return (
        <View style={styles.container}>

           

                <View style={styles.statics}>

                    <Text style={[styles.text, { fontStyle: 'normal', fontWeight: 'bold' }]} >Runden</Text>
                    <Text style={[styles.text, { fontStyle: 'normal' }]}>{wallet}</Text>

                </View>

                <View style={styles.statics}>

                    <Text style={[styles.text, { fontStyle: 'normal', fontWeight: 'bold' }]}>Balance</Text>
                    <Text style={[styles.text, { color: 'green' }]}>{wallet} <FontAwesome5 name="coins" size={16} color="#ffd700" /></Text>

                </View>

                <View style={styles.statics}>

                    <Text style={[styles.text, { fontStyle: 'normal', fontWeight: 'bold' }]}>BigWin</Text>
                    <Text style={[styles.text, { fontStyle: 'normal' }]}>250</Text>

                </View>
            
        </View>
    )
}


const styles = StyleSheet.create({

    container: {
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'flex-start',

    },

    statics: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 14,
        borderColor: Colors.orange,
        borderWidth: 2,
        marginLeft: 1,
        marginRight: 1,


    },

    text: {
        color: Colors.white
    }

})