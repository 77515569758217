import { Image, StyleSheet, Text, View ,Button, TouchableOpacity} from 'react-native'
import React from 'react'
import { firelistener, firestorage } from '../../../../../hooks'
import { Colors } from '../../../../../config'
import { LoadingIndicator } from '../../../../../components'
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";



export const ProfileBanner = (props) => {
    const { wallet, nickname } = firelistener();

    const { imgURL, setProfileImage } = firestorage();
    return (
        <View style={styles.container}>

            <View style={styles.viewport} >

            <Text style={[styles.textview,{fontSize:20}]}>{nickname}</Text>
           
            
            </View>

            {
                //
            }

            <View style={styles.viewport} >

                <Image style={styles.Image} source={{ uri: imgURL }} />

            </View>


            {
                //
            }

            <View style={styles.viewport} >
                <TouchableOpacity onPress={() =>{
                    props.navigation.navigate('profile',{screen:'paypal'})
                }} ><FontAwesome5 name="paypal" size={30} color={Colors.white} /></TouchableOpacity>
            </View>
            {
                //
            }



        </View>
    )
}


const styles = StyleSheet.create({

    container: {
        paddingTop: 2,
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        backgroundColor: Colors.orange, 
        
    },

    viewport: {

        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'center',
        
    },
    Image: {
        height: 120,
        width: 120,
        borderRadius: 60,
        marginTop: 0,
        borderColor: Colors.white,
        borderWidth: 1
       

    },

    textview: {
        color: Colors.white
    }

})