import { Text, StyleSheet, View, Button, StatusBar, TouchableOpacity, Pressable } from 'react-native'
import React, { Component, useState, useRef, useEffect } from 'react'


export const TestScreen = () => {


  return (
    <View style={styles.container}>
      <StatusBar />
    </View>
  )
}

const styles = StyleSheet.create({

  container: {
    flex: 1,
    backgroundColor: 'grey'
  }

})