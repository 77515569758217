import { StyleSheet, Text, View, ImageBackground, Image, Button, Platform, TouchableOpacity } from "react-native";
import { DrawerContentScrollView, DrawerItemList, DrawerItem } from "@react-navigation/drawer";
import { Colors } from "../config";
import { signOut } from "firebase/auth";
import Ionicons from "react-native-vector-icons/Ionicons";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import { auth } from "../config";
import { getDatabase, ref, set } from "firebase/database";
import { firelistener } from "../hooks";
import { firestorage } from "../hooks";
import DocumentPicker, { DirectoryPickerResponse, DocumentPickerResponse, isInProgress, types } from 'react-native-document-picker'
import * as DocumentPickerE from 'expo-document-picker';


export const CustomDrawer = (props) => {
  const handleLogout = () => {
    signOut(auth).catch((error) => console.log("Error logging out: ", error));
  };
  const { wallet, nickname } = firelistener();

  const { imgURL, setProfileImage } = firestorage();

  const handleError = (err) => {
    if (DocumentPicker.isCancel(err)) {
      console.warn('cancelled')
      // User cancelled the picker, exit any dialogs or menus and move on
    }
  }

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: Colors.transparent,
      }}
    >
      <DrawerContentScrollView
        {...props}
        style={styles.DrawerContentScrollView}
        contentContainerStyle={{
          backgroundColor: Colors.transparent,
          color: Colors.white,
        }}
      >
        <ImageBackground style={styles.ImageBackground}>
          <TouchableOpacity onPress={async () => {


            /*if (Platform.OS === 'android') {
              try {
                const pickerResult = await DocumentPicker.pickSingle({
                  presentationStyle: 'fullScreen',
                  copyTo: 'cachesDirectory',
                })
                setProfileImage(pickerResult);
              } catch (e) {
                handleError(e)
              }
            }else if(Platform.OS === 'web'){
              try {
                const pickerResult = await DocumentPickerE.getDocumentAsync({
                  type: 'image/*',
                  copyToCacheDirectory: true,
                  multiple: false,
                })
                setProfileImage(pickerResult.);
              } catch (e) {
                handleError(e)
              }


            }*/
            try {
              const pickerResult = await DocumentPickerE.getDocumentAsync({
                type: 'image/*',
                copyToCacheDirectory: true,
                multiple: false,

              })
              setProfileImage(pickerResult);
            } catch (e) {
              handleError(e);
            }
          }}>
            <Image source={{uri: imgURL}} style={styles.Image} />
          </TouchableOpacity>


          <Text style={styles.nameText}>{nickname}</Text>

          <View style={{ flexDirection: "row", marginTop: 5, float: 'right' }}>
            <Text style={styles.ballanceText}>{wallet}</Text>
            <FontAwesome5 name="coins" size={16} color="#ffd700" />
          </View>
        </ImageBackground>
        <View style={{ flex: 1, paddingTop: 5 }}>
          <DrawerItemList
            {...props}
            style={{
              flex: 1,
              backgroundColor: Colors.mediumGray,
              activeTintColor: Colors.white,
            }}
          />
        </View>

        <View
          style={styles.viewButtomshare}
        >
          <FontAwesome5
            name="share-alt"
            size={16}
            color="#fff"
            style={{ marginTop: 15 }}
          />
          <Text
            style={{
              marginTop: 15,
              justifyContent: "center",
              alignContent: "center",
              textAlign: "center",
              marginLeft: 5,
              color: Colors.white
            }}
          >
            teile die App mit Freunden
          </Text>
        </View>
      </DrawerContentScrollView>

      <View style={{}}>

        <Button
          style={styles.logoutButton}
          title="ausloggen"
          onPress={handleLogout}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  logoutButton: {
    borderRadius: "12px",
  },
  nameText: {

    color: Colors.black,
    fontSize: 12,


    // other platforms, web for example
    fonFamily: "Roboto-Medium",
    color: Colors.white,

  },
  ballanceText: {

    // other platforms, web for example
    color: 'green',
    fontSize: 15,
    marginRight: 5,
    fonFamily: "Roboto-Medium",
    float: 'right',

  },
  DrawerContentScrollView: {

  },
  ImageBackground: {

    padding: 20,
    backgroundColor: Colors.transparent,

  },
  Image: {

    height: 80,
    width: 80,
    borderRadius: 40,
    marginBottom: 10,

  },
  viewButtomshare: {

    flex: 1,
    flexDirection: "row",
    borderTopColor: Colors.orange,
    borderTopWidth: 1,
    marginTop: 100,
    justifyContent: "center",
    alignContent: "center",

  },
});
