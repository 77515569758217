import React, { useEffect, useRef, useState } from 'react';
import { StatusBar } from 'expo-status-bar';
import { StyleSheet, View, Alert, Text } from 'react-native';
import { Star } from '../../components/Models/shapes';
import PerfTimer from '../../components/Models/Timer';
import { useComponentSize } from '../../hooks';

export default function StarCatcher(props) {

    const clickCount = useRef(0);
    const starList = useRef([]);
    const startTime = useRef(null);
    
    const timer = useRef(null);
    
    const [size, onLayout] = useComponentSize();
    const [appReady, setAppReady] = useState(false);
    const [stars, setStars] = useState([]);
    const currentTime = useRef(0);

    const randomNumber = (min, max) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    function between(x, min, max) {
        return x >= min && x <= max;
    }

    function createStartStars() {
        setAppReady(false);
        startTime.current = null;
        clickCount.current = 0;
        starList.current = [];
        let positionList = [props.starCount];
        const objWidth = (30);
        const objHeight = (40);
        let index = 0;

        for (positionList; positionList.length < props.starCount; index++) {

            let x = 0;
            let y = 0;

            x = randomNumber(0, (size.width - objWidth))
            y = randomNumber(0, (size.height - objHeight))

            positionList.push({ id: index, x: x, y: y })
            let counter = 0;
            positionList.forEach((obj) => {

                if (obj.id !== index) {
                    if (!between(x, obj.x, (obj.x + objWidth)) && !between(y, obj.y, (obj.y + objHeight))) {
                    } else {
                        counter++;
                        positionList.slice(index, 1);
                    }
                }
            });
            if (counter > 0) {
                positionList = positionList.slice(0, positionList.length - 1);
                continue;
            } else if (counter === 0) {
                starList.current.push(<Star key={index} position={[x, y]} width={objWidth} height={objHeight} layoutSize={size} entityClick={() => entityClick()} />);
            }
        }
        setStars(starList.current);

    }

    useEffect(() => {

        
        if (size) {
            if(!appReady){
                createStartStars();
            }
            
        }
        return () => {
            setAppReady(false);
        };
    }, [size]);

    useEffect(() => {

        if (stars) {

            setAppReady(true)
        }

        return () => {

        };
    }, [stars]);

    useEffect(() => {

        timer.current = new PerfTimer();
        timer.current.subscribe(handleTimerCallback);

        return () => {
            timer.current.unsubscribe(handleTimerCallback);
        };
    }, []);

    const entityClick = () => {

        clickCount.current++;
        if (clickCount.current === 1) {
            startTime.current = new Date();
            timer.current.start();
        }
        if (clickCount.current === props.starCount - 1) {
            timer.current.stop();
            showAlert('GameOver', 'Du hast das Spiel Beendet, deine Zeit: ' + ((currentTime.current - startTime.current)) + '. Nochmal? ', 'gerne', 'nein');
            console.log((startTime.current));
            
        }
    }

    const showAlert = (titel, message, buttontextConfirm, buttonTextCancel) =>
        Alert.alert(
            titel,
            message,
            [
                {
                    text: buttonTextCancel,
                    onPress: () => Alert.alert("Schade"),
                    style: "cancel",
                },
                {
                    text: buttontextConfirm,
                    onPress: () => createStartStars(),
                    style: "confirm",
                },
            ],
            {
                cancelable: false,
                onDismiss: () => {
                    setAppReady(false);
                    startTime.current = null;
                    clickCount.current = 0;
                    starList.current = [];
                },
            }
        );


    const handleTimerCallback = (currentTimeL) => {

        currentTime.current = new Date();
        setStars((stars) => [...stars]);

    }

    return (

        <View style={styles.container}>

            <View style={styles.header} >
                {startTime.current && <Text>{(currentTime.current - startTime.current)}</Text>}
            </View>

            <View style={styles.Gamecontainer} onLayout={onLayout} >
                {appReady ?
                    stars.map((item) => {
                        return item
                    })
                    : null}
            </View>
        </View>
    );
}

const styles = StyleSheet.create({

    container: {
        flex: 1,
        backgroundColor: 'transparent',
    },
    header: {

        flex: 1,
        alignContent: 'center',
        alignItems: 'center',
        backgroundColor: 'green',

    },
    Gamecontainer: {
        flex: 9,
        backgroundColor: 'black',
    },

});
