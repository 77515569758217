import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { Colors } from '../config'
import { Button } from './Button'

export const ThemenBox = (arg) => {
  
  
    const defaultStyles = {
        borderColor: Colors.red,
        borderWidth: 2,
        borderRadius: 12,
        width:300,
        height:150,
        padding:0,
        flex:1
    
    }
  


    return (
    
    <View style={arg.style ? arg.style : defaultStyles}>
        <View style={{flex:1 , padding:5}}>
        <Button style={{height:20}} >
                  <Text style={{color: Colors.white}}>Menue</Text>
                </Button>

        </View>
        <View style={{flex:13 }}>

        </View>
    </View>
  )
}



const styles = StyleSheet.create({

    container:{
        borderColor: Colors.red,
        borderWidth: 2,
        borderRadius: 12,
        width:150,
        height:75,
        padding:5,
        

    }

})