import { StyleSheet, Text, View } from 'react-native'
import React from 'react'

export const BestPlayerContent = () => {
  return (
    <View>
      <Text>index</Text>
    </View>
  )
}



const styles = StyleSheet.create({})