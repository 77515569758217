import React, { useState, useContext, useEffect } from 'react';
import { createNavigationContainerRef, NavigationContainer } from '@react-navigation/native';
import { onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth';

import { AuthStack } from './AuthStack';
import { AppStack } from './AppStack';
import { AppIsLoadingContext, AuthenticatedUserContext } from '../providers';
import { LoadingIndicator } from '../components';
import { auth } from '../config';

export const RootNavigator = () => {
  const { user, setUser } = useContext(AuthenticatedUserContext);
  //const { appIsLoading, appSetLoading } = useContext(AppIsLoadingContext);

  useEffect(() => {
 
    signInWithEmailAndPassword(auth, 'admin@playeasyandsafe.de', '1989Weedbauer!').then(
      //appSetLoading(false)
    ).catch(error =>
      setErrorState(error.message)
    );
    return () => {
      
    };
  }, []);

  useEffect(() => {
    //console.log('RootNavigator : appLoading => '+ appIsLoading);
    // onAuthStateChanged returns an unsubscriber
    const unsubscribeAuthStateChanged = onAuthStateChanged(
      auth,
      authenticatedUser => {
        authenticatedUser ? setUser(authenticatedUser) : setUser(null);
      }
    );

    // unsubscribe auth listener on unmount
    return unsubscribeAuthStateChanged;
  }, [user]);


  /*if (appIsLoading) {
    return <LoadingIndicator />;
  }*/

  return (
    <NavigationContainer >
      {user ? <AppStack user /> : <AuthStack />}
    </NavigationContainer>
  );
};
