import { Text, StyleSheet, View } from 'react-native'
import React, { Component } from 'react'
import WebView from 'react-native-webview'

export default class PayPal extends Component {

    state = {
        approval_url: null,
        accessToken: null,
        paymentId: null

    }

    componentDidMount() {

        console.log('mounted');
    }

    render() {
        const approvalUrl = this.state.approval_url;
        return (
            <View style={{flex:1}}>
                {true ?
                    checkout()
                    :
                    editPayment()
                }

            </View>
        )
    }
}

 function checkout() {
    return(
    <WebView style={{width:'10', height: '100'}} source={{ uri: 'https://google.de' }} javaScriptEnabled  >

    </WebView>
    )
}

 function editPayment() {
    return(
    <View style={styles.editContainer} >
        <Text style={{color:'white'}}>fghdfghdfghdfg</Text>
    </View>
    )

}
const styles = StyleSheet.create({

    editContainer:{
        flex:1,
        justifyContent: 'center',
        alignItems:'center'
    },

    webContainer:{

    }

})