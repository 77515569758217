import * as React from 'react';
import { Colors } from '../config';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { AboutUsScreen, GamesScreen, ProfileScreen, HomeScreen, TestScreen } from '../screens/AppScreens';
import { View, StyleSheet, Button, Platform } from 'react-native';
import { CustomDrawer } from '../components';
import Ionicons from 'react-native-vector-icons/Ionicons'

const Drawer = createDrawerNavigator();
const forSlide = ({ current, next, inverted, layouts: { screen } }) => {
  const progress = Animated.add(
    current.progress.interpolate({
      inputRange: [0, 1],
      outputRange: [0, 1],
      extrapolate: 'clamp',
    }),
    next
      ? next.progress.interpolate({
        inputRange: [0, 1],
        outputRange: [0, 1],
        extrapolate: 'clamp',
      })
      : 0
  );

  return {
    cardStyle: {
      transform: [
        {
          translateX: Animated.multiply(
            progress.interpolate({
              inputRange: [0, 1, 2],
              outputRange: [
                screen.width, // Focused, but offscreen in the beginning
                0, // Fully focused
                screen.width * -0.3, // Fully unfocused
              ],
              extrapolate: 'clamp',
            }),
            inverted
          ),
        },
      ],
    },
  };
};
export const AppStack = () => {

  return (
    <View style={styles.container}>
    <Drawer.Navigator drawerContent={(props) => <CustomDrawer {...props} />} useLegacyImplementation initialRouteName="Home"
      screenOptions={{

        ...Platform.select({

          android:{
            headerShown: false, drawerLabelStyle: { marginLeft: -25, fontSize: 15 },
            drawerInactiveTintColor: '#dd4d00',
            drawerActiveTintColor: '#f57c00',
            drawerPosition: 'left',
          },
          default:{
            headerShown: true, drawerLabelStyle: { marginLeft: -25, fontSize: 15 },
            drawerInactiveTintColor: '#dd4d00',
            drawerActiveTintColor: '#f57c00',
            drawerPosition: 'left',
          }

        }),
        cardStyle: {
          backgroundColor: Colors.background,
          cardStyleInterpolator: forSlide
        }
      }}>
      <Drawer.Screen name="Home" component={HomeScreen}
        options={{
          drawerIcon: ({ color }) => (
            <Ionicons name="home-outline" size={22} color={color} />
          )
        }} />
      <Drawer.Screen name="Spielbereich" component={GamesScreen}
        options={{
          
          drawerIcon: ({ color }) => (
            <Ionicons name="game-controller-outline" size={22} color={color} />
          )
        }} />
      <Drawer.Screen name="profile" component={ProfileScreen}
        options={{
          drawerIcon: ({ color }) => (
            <Ionicons name="person-outline" size={22} color={color} />
          )
        }} />
      <Drawer.Screen name="aboutUs" component={AboutUsScreen}
        options={{
          drawerIcon: ({ color }) => (
            <Ionicons name="eye-outline" size={22} color={color} />
          )
        }} />

    </Drawer.Navigator>
    </View>
  );
};

const styles = StyleSheet.create({

  container:{
    flex:1
  }

});
