import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { Button } from '../components'
import { getHeaderTitle } from '@react-navigation/elements';
import { Colors } from '../config';


export const MainAcivityNavigation = (props) => {

    
    return (
        <View style={styles.container}>

            <View style={{ flex: 1 }}>
            <Button
                    style={styles.borderlessButtonContainer}
                    borderless
                    title={'Infos'}
                    onPress={() => props.navigation.navigate('Home',{screen:'InfoCenter'})}
                />
            </View>

            <View style={{ flex: 1 }}>
                <Button
                    style={styles.borderlessButtonContainer}
                    borderless
                    title={'Beste Spieler'}
                    onPress={() => props.navigation.navigate('Home',{screen:'BestPlayer'})}
                />
            </View>

            <View style={{ flex: 1 }}>
                <Button
                    style={styles.borderlessButtonContainer}
                    borderless
                    title={'Beste Zeiten'}
                    onPress={() => props.navigation.navigate('Home',{screen:'PlayerTimes'})}
                />
            </View>
            <View style={{ flex: 1 }}>
            <Button
                    style={styles.borderlessButtonContainer}
                    borderless
                    title={'History'}
                    onPress={() => props.navigation.navigate('Home',{screen:'History'})}
                />
            </View>
        
        </View>
    )
}


const styles = StyleSheet.create({

    container: {
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'flex-start'
    },

    borderlessButtonContainer: {
        flex:1,
        marginTop: 5,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.orange,
        borderRadius:12,
        marginLeft:1,
        marginRight:1,
        color: Colors.black
        
    }

})