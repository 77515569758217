import * as Yup from 'yup';

export const loginValidationSchema = Yup.object().shape({
  email: Yup.string().required("Bitte eine gültige Email eintragen").email().label('Email'),
  password: Yup.string().required('Passwort benötigt min. 6 Zeichen').min(6).label('Password')
});

export const signupValidationSchema = Yup.object().shape({
  email: Yup.string().required("Bitte eine gültige Email eintragen").email().label('Email'),
  password: Yup.string().required('Passwort benötigt min. 6 Zeichen').min(6).label('Password'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwörter müssen übereinstimmen.')
    .required('Passwort bestätigen ist erforderlich.')
});

export const passwordResetSchema = Yup.object().shape({
  email: Yup.string()
    .required('Bitte eine gültige Email eintragen')
    .label('Email')
    .email('Bitte eine gültige Email eintragen')
});
