import { View, Text, StyleSheet, TouchableOpacity, Animated, Image } from 'react-native'
import React, { useState, useEffect, useRef } from 'react'



export default function Star(props) {

    const pressed = useRef(false);
    const [rotateAnimation, setRotateAnimation] = useState(new Animated.Value(0));
    const fadeAnim = useRef(new Animated.Value(1)).current;

    const handleClick = (e) => {
        console.log();
        if (!pressed.current) {
            props.entityClick();
            pressed.current = true;
        }
        Animated.timing(rotateAnimation, {
            toValue: 1,
            duration: 400,
            useNativeDriver: true
        }).start(() => {
            rotateAnimation.setValue(0);
        });
        fadeOut();

    }


    const fadeOut = () => {
        Animated.timing(fadeAnim, {
            toValue: 0,
            duration: 450,
            useNativeDriver: true
        }).start();
    };


    const interpolateRotating = rotateAnimation.interpolate({
        inputRange: [0, 1],
        outputRange: ['0deg', '360deg'],
    });

    useEffect(() => {

        Animated.loop(
            Animated.timing(rotateAnimation, {
                toValue: 1,
                duration: 360,
                useNativeDriver: true,
                
            })
            , { 
                useNativeDriver: true,
                isInteraction: false
            
            }).start(() => {
                rotateAnimation.setValue(0);
            });
        return () => {

        };
    }, []);

    return (
        <View >
            <TouchableOpacity onPressIn={async (e) => handleClick(e)}>
                <Animated.View style={[styles.ground,
                {
                    position: 'absolute',
                    left: props.position[0],
                    top: props.position[1],
                    width: props.width,
                    height: props.height,
                    transform: [{ rotate: interpolateRotating }],
                    opacity: fadeAnim,
                    alignItems: 'center',
                    alignContent: 'center'

                }]}>



                </Animated.View>
            </TouchableOpacity>
        </View>
    )
}

const styles = StyleSheet.create({
    ground: {
        backgroundColor: 'fuchsia',
        borderRadius: (5 * Math.PI),
        borderWidth: 5,
        borderColor: 'green'
    }
});