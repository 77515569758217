import { getApp, getApps, initializeApp } from "firebase/app";
import { initializeAuth, getReactNativePersistence } from 'firebase/auth';
import Constants from 'expo-constants';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Platform } from 'react-native';
import { getDatabase } from 'firebase/database';
import { getFirestore } from "firebase/firestore";

// add firebase config
const firebaseConfig = {
  apiKey: Constants.manifest.extra.apiKey,
  authDomain: Constants.manifest.extra.authDomain,
  databaseURL: 'https://playeasyandsafe-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: Constants.manifest.extra.projectId,
  storageBucket: Constants.manifest.extra.storageBucket,
  messagingSenderId: Constants.manifest.extra.messagingSenderId,
  appId: Constants.manifest.extra.appId,
};


// initialize firebase
const app = !getApps().length ? initializeApp(firebaseConfig) : getApp()
//const app = initializeApp(firebaseConfig);

const store = getFirestore(app);
const auth = initializeAuth(app); 

export { auth,store };
