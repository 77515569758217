export const Colors = {
  orange: '#f57c00',
  blue: '#039be5',
  black: '#222222',
  white: '#ffffff',
  mediumGray: '#6e6869',
  red: '#fc5c65',
  transparent: '#000000',
  background: '#516981'
};
