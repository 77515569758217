import { auth, db } from "../config";
import { ref, set, onValue } from "firebase/database";
import { useState,useEffect } from "react";



export const firelistener = () => {

    const [wallet, setWallet] = useState(null);
    const [nickname, setNickname] = useState(null);

    const [image, setImage] = useState(null);
    
    const walletRef = ref(db, 'users/wallets/' + auth.currentUser.uid + '/balance');
    const nameref = ref(db, 'users/informations/' + auth.currentUser.uid + '/nickname');
    
    useEffect(() => {
        
        onValue(walletRef, (snapshot) => {
            const data = snapshot.val();
            setWallet(data);

        });
        
        onValue(nameref, (snapshot) => {
            const data = snapshot.val();
            setNickname(data);

        });

    }, [])

    return {
        wallet,
        nickname
    }
}