import React, { useState, createContext } from 'react';
import {StyleSheet } from 'react-native';
export const AuthenticatedUserContext = createContext({});
import { Colors } from '../config';


export const AuthenticatedUserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  return (
    <AuthenticatedUserContext.Provider value={{ user, setUser }} style={{flex:1}}>
      {children}
    </AuthenticatedUserContext.Provider>
  );
};
