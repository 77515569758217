import { createContext, useState } from 'react';
export const AppIsLoadingContext = createContext({});

export const AppStateIndicatorProvider = ({arg}) =>{

    const  [appIsLoading, appSetLoading] = useState(false);

   
    return (
       <AppIsLoadingContext.Provider value={{appIsLoading, appSetLoading}}>
       {arg}
       </AppIsLoadingContext.Provider>
       )   
}